<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tags">Ticket Tags</a>
    </div>
    <div class="mb-2 d-block d-lg-none d-md-none">
      <div class="btn btn-sm btn-block form-control btn-primary p-2" v-on:click="newTag">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Ticket Tag
      </div>
    </div>
    <div class="row list-actions">
      <div class="col left-column">
        <div class="list-tab active" v-on:click="getTags">
          All<span>{{tags.length}}</span>
        </div>
      </div>
      <div class="col right-column d-none d-lg-block d-md-block">
        <div class="btn btn-sm btn-primary" v-on:click="newTag">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Ticket Tag
        </div>
      </div>
    </div>
    <SearchComponent
      @searchRecord="searchRecord($event)"
      :placeholder="'Search ticket tag by name or code'"
      :showSideOptions="false"
      :isRealTime="true"
    ></SearchComponent>
    <ListComponent
      :records="filteredTags"
      :profile="'tags'"
    ></ListComponent>
  </div>
</template>

<script>
  import SearchComponent from '../Search.vue'
  import ListComponent from '../List.vue'

  export default {
    components: {
      SearchComponent,
      ListComponent
    },
    data() {
      return {
        user: this.$userData.user,
        tags: [],
        filteredTags: []
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getTags(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags?isInternal=${this.$internalMode}&override=${this.user.role == 'Admin' ? true : false}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.tags = response.data.tags
          this.filteredTags = this.buildList(this.tags)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      searchRecord: function(search){
        this.filteredTags = this.buildList(this.tags.filter(record => (
          (record.code ? record.code.toLowerCase().match(new RegExp(search.toLowerCase())) : false) ||
          (record.name ? record.name.toLowerCase().match(new RegExp(search.toLowerCase())) : false)
        )))
      },
      buildList: function(records){
        return records.map((record) => {
          return {
            key: record.code,
            header: `[${record.code}] ${record.name}`,
            subheader: record.description,
            indicators: [
              {
                key: `${record._id}_1`,
                text: record.type,
                background: "primary-default"
              }
            ],
            timestamp: {},
            record: record
          }
        })
      },
      toCapitalizedWords: function (string) {
        var words = string.match(/[A-Za-z][a-z]*/g) || [];
        return words.map(this.capitalize).join(" ");
      },
      capitalize: function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1);
      },
      validateUser: async function(isValid){
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: `You don't have access for this module.`
          })
          await this.$sleep(500);
          this.$router.push('/')
        }
      },
      newTag: function(){
        this.$router.push('/tags/new')
      }
    },
    mounted: function() {
      document.title = `Ticket Tags | iRipple Helpdesk`;
      this.validateUser(['Admin', 'Manager'].includes(this.user.role))
      this.getTags()
    }
  }
</script>
